import React, { useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import styles from "../admin/pages/Common.module.css";
import { useData } from "../context/DataContext";

// website

import Home from "../pages/Home";
import Careers from "../pages/Careers";
import CareersView from "../pages/CareersView";
import ContactUs from "../pages/ContactUs";
import Crypto from "../pages/Crypto";
import Stocks from "../pages/Stocks";
import Recruitment from "../pages/Recruitment";
import About from "../pages/About";

// auth
import Login from "../admin/auth/Login";

// adminDashboard
import AdminNavbar from "../admin/AdminMenu";
import HeaderProfile from "../admin/pages/HeaderProfile";
import Dashboard from "../admin/pages/Dashboard";
import JobList from "../admin/pages/JobList";
import MasterData from "../admin/pages/MasterData";
import JobView from "../admin/pages/JobView";
import JobAdd from "../admin/pages/JobAdd";
import DataView from "../admin/pages/DataView";

const Main = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // useEffect(() => {
  //   const handleRefresh = (event) => {
  //     if (event.persisted) {
  //       navigate("/");
  //     }
  //   };
  //   window.addEventListener("beforeunload", handleRefresh);
  //   return () => {
  //     window.removeEventListener("beforeunload", handleRefresh);
  //   };
  // }, [navigate]);

  // useEffect(() => {
  //   if (location.pathname === "/") {
  //     navigate("/");
  //   }
  // }, [location.pathname, navigate]);

  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="aboutus" element={<About />} />
        <Route path="services">
          <Route path="crypto" element={<Crypto />} />
          <Route path="stocks" element={<Stocks />} />
          <Route path="recruitment" element={<Recruitment />} />
        </Route>
        <Route path="careers">
          <Route path="jobs" element={<Careers />} />
          <Route path=":code" element={<CareersView />} />
        </Route>
        <Route path="contact" element={<ContactUs />} />
        <Route path="login" element={<Login />} />
      </Routes>
    </div>
  );
};

export default Main;

export const AdminRoute = () => {
  const { adminDrawer } = useData();

  return (
    <>
      <div className={styles.area}>
        <AdminNavbar />
        <div
          className={adminDrawer ? styles.fullRight_cont : styles.right_cont}>
          <div className={styles.top}>
            <HeaderProfile />
          </div>
          <div className={styles.main_layer}>
            <Routes>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="jobs" element={<JobList />} />
              <Route path="masterData" element={<MasterData />} />
              <Route path="createJob" element={<JobAdd />} />
              <Route path="jobUpdates" element={<JobView />} />
              <Route path="detail" element={<DataView />} />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
};

import React, { createContext, useContext, useState } from "react";

const DataContext = createContext();

export const useData = () => {
  return useContext(DataContext);
};

export const DataProvider = ({ children }) => {
  const [data, setData] = useState([]);
  const [isAuthenticate, setIsAuthenticate] = useState(null);
  const [adminDrawer, setAdminDrawer] = useState(null);
  const [adminJobData, setAdminJobData] = useState([]);
  const [clientDetail, setClientDetail] = useState([]);
  const [loading, setLoading] = useState(true);
  const url = "https://crs-server-3cf7.onrender.com";

  const setSharedData = (newData) => {
    setData(newData);
  };

  const setAuthenticate = (newData) => {
    setIsAuthenticate(newData);
  };

  const setAdminDraw = (newData) => {
    setAdminDrawer(newData);
  };

  const setAdminJobDetail = (newData) => {
    setAdminJobData(newData);
  };

  const setClient = (newData) => {
    setClientDetail(newData);
  };

  const setSpinner = (newData) => {
    setLoading(newData);
  };
  return (
    <DataContext.Provider
      value={{
        data,
        setSharedData,
        isAuthenticate,
        setAuthenticate,
        adminDrawer,
        setAdminDraw,
        adminJobData,
        setAdminJobDetail,
        clientDetail,
        setClient,
        loading,
        setSpinner,
        url,
      }}>
      {children}
    </DataContext.Provider>
  );
};

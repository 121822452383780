import React, { useState, useEffect } from "react";
import styles from "./Careers.module.css";
import Banner from "../components/Banner";
import Images from "../common/Images";
import Icons from "../common/Icons";
import { Button } from "@mui/material";
import Paginations from "../components/Paginations";
import { useNavigate } from "react-router-dom";
import { useData } from "../context/DataContext";
import axios from "axios";
import Loader from "../components/Loader";

const Careers = () => {
  const navigate = useNavigate();
  const { setSharedData, url, loading, setSpinner } = useData();

  const [getData, setGetData] = useState({
    jobTitle: "",
    location: "",
  });
  const [colorChange, setColorchange] = useState(false);
  const [data, setData] = useState([]);
  const [stepnie, setStepnie] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const pageData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    let dataToDisplay;

    if (filterData.length > 0) {
      dataToDisplay = filterData.slice(startIndex, endIndex);
    } else {
      dataToDisplay = data.slice(startIndex, endIndex);
    }
    setData(dataToDisplay);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    pageData();
  }, [filterData, currentPage]);

  const fetchData = async () => {
    const response = await axios.get(`${url}/job`);
    if (response) {
      setData(response.data);
      setStepnie(response.data);
      // setAwait(false);
      setSpinner(false)
    } else {
      // setAwait(true);
      setData([]);
    }
  };
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGetData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (!value.trim()) {
      setData(stepnie);
    }
  };

  const handleSearch = () => {
    const { jobTitle, location } = getData;

    const filteredData = data.filter((career) => {
      const jobTitleMatch =
        !jobTitle ||
        career.designation.toLowerCase().includes(jobTitle.toLowerCase());
      const locationMatch =
        !location ||
        career.location.toLowerCase().includes(location.toLowerCase());

      return jobTitleMatch && locationMatch;
    });
    setFilterData(filteredData);

    setCurrentPage(1);
    // setGetData({
    //   jobTitle: "",
    //   location: "",
    // });
  };

  const handleView = (jobData, code) => {
    localStorage.setItem("jobId", code);
    console.log("crv", jobData);
    setSharedData(jobData);
    navigate(`/careers/${code}`);
  };

  const handleApply = (dataLink) => {
    window.open(dataLink, "_blank");
  };

  return (
    <>
      {loading && loading ? (
        <Loader />
      ) : (
        <>
          <Banner Image={Images.careersBanner} Title={"Careers"} />
          <div className={styles.careers_main}>
            <div
              className={
                colorChange
                  ? styles.careers_filter_stick
                  : styles.careers_filter
              }>
              <h4>Find Jobs</h4>
              <div className={styles.input_filter}>
                <input
                  placeholder="search for job by title"
                  type="text"
                  max={20}
                  className={styles.job_input}
                  name={"jobTitle"}
                  value={getData.jobTitle}
                  onChange={handleChange}
                />
                <div className={styles.location_box}>
                  <Icons.LocationIcon className={styles.location_icon} />
                  <input
                    placeholder="Location"
                    type="text"
                    max={15}
                    name={"location"}
                    value={getData.location}
                    onChange={handleChange}
                  />
                </div>
                <Icons.SearchIcon
                  className={styles.search_icon}
                  onClick={handleSearch}
                />
              </div>
            </div>
            <div className={styles.careers_list}>
              {/* {data.length > 0 ? ( */}
              {data &&
                data.map((item) => (
                  <div className={styles.list_cont} key={item.id}>
                    <div className={styles.left_con}>
                      <h4>{item.designation}</h4>
                      <h6>
                        {item.location}, {item.country}
                      </h6>
                      <div className={styles.btnBox}>
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() => handleView(item, item.jobCode)}
                          className={styles.btns}>
                          View Jobs
                        </Button>
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() => handleApply(item.job_link)}
                          className={styles.btns}>
                          Apply Now
                        </Button>
                      </div>
                    </div>
                    <div className={styles.right_con}>
                      <p>Posted {item.date}</p>
                    </div>
                  </div>
                ))}
              {/* // ) : (
              //   <div className={styles.placeHolder}>
              //     <h2>There is No Careers yet! Be Patience with us.</h2>
              //   </div>
              // )} */}
            </div>
            <Paginations
              className={styles.pagin}
              activePage={currentPage}
              onPageChange={handlePageChange}
              itemCount={data.length}
              itemsPerPage={itemsPerPage}
            />
          </div>
        </>
      )}
    </>
  );
};

export default Careers;

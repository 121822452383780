import React, {useEffect, useState} from "react";
import styles from "./About.module.css";
import Images from "../common/Images";
import Headers from "../components/Header";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import Banner from "../components/Banner";
import { useNavigate } from "react-router-dom";

const About = () => {
  const gallery = [
    {
      id: 1,
      image: Images.gallery_image_1,
    },
    {
      id: 2,
      image: Images.gallery_image_2,
    },
    {
      id: 3,
      image: Images.gallery_image_3,
    },
    {
      id: 4,
      image: Images.gallery_image_4,
    },
    {
      id: 5,
      image: Images.gallery_image_5,
    },
    {
      id: 6,
      image: Images.gallery_image_6,
    },
    {
      id: 7,
      image: Images.gallery_image_7,
    },
    {
      id: 8,
      image: Images.gallery_image_8,
    },
    {
      id: 9,
      image: Images.gallery_image_9,
    },
    {
      id: 10,
      image: Images.gallery_image_10,
    },
    {
      id: 11,
      image: Images.gallery_image_11,
    },
    {
      id: 12,
      image: Images.gallery_image_12,
    },
    {
      id: 13,
      image: Images.gallery_image_13,
    },
    {
      id: 14,
      image: Images.gallery_image_14,
    },
    {
      id: 15,
      image: Images.gallery_image_15,
    },
  ];

  const Team = [
    // {
    //   id: 1,
    //   name: "Shuaib",
    //   designation: "Director",
    //   image: Images.Shuaib,
    // },
    {
      id: 2,
      name: "Aadil Latheef",
      designation: "Associate Director",
      image: Images.Adil,
    },
    {
      id: 3,
      name: "Mohamed Ibrahim",
      designation: "Director of Operations",
      image: Images.Ibrahim,
    },
    {
      id: 4,
      name: "Mohammed Ayaz",
      designation: "Senior Consultant",
      image: Images.Ayaz,
    },
    {
      id: 5,
      name: "Mohammed Maseeullah",
      designation: "Senior Consultant",
      image: Images.Masiullah,
    },
    {
      id: 6,
      name: "Mohammed Ashraf",
      designation: "Senior. Consultant",
      image: Images.Ashraf,
    },
    {
      id: 7,
      name: "Mohammed Hassan",
      designation: "Portfolio Manager",
      image: Images.Hassan,
    },
    {
      id: 8,
      name: "Adnan Qureshi",
      designation: "Manager - FSS ",
      image: Images.Adnan,
    },
    {
      id: 9,
      name: "Mohammed Usama",
      designation: "Vertical Lead",
      image: Images.Usama,
    },
    {
      id: 10,
      name: "Waseem Ahmed",
      designation: "Client Relationship Executive",
      image: Images.Waseem,
    },
    {
      id: 11,
      name: "Alfaz",
      designation: "Senior Associate",
      image: Images.Alfaz,
    },
    {
      id: 12,
      name: "Nihad Ahmed",
      designation: "BDE & Talent Acquisition ",
      image: Images.Nihad,
    },
    {
      id: 13,
      name: "Hiba Aamina",
      designation: "Talent Acquisition Specialist",
      image: Images.Hiba,
    },
    {
      id: 14,
      name: "Umme Aiman ",
      designation: "Talent Acquisition Specialist ",
      image: Images.Hiba,
    },
  ];
// const navigate = useNavigate();

const navigate = useNavigate();
const [isRefreshing, setIsRefreshing] = useState(false);

useEffect(() => {
  const handleBeforeUnload = () => {
    setIsRefreshing(true);
  };

  const handleUnload = () => {
    if (isRefreshing) {
      console.log("Page is being refreshed");
      // Redirect to the homepage
      navigate("/");
    }
  };

  window.addEventListener("beforeunload", handleBeforeUnload);
  window.addEventListener("unload", handleUnload);

  return () => {
    window.removeEventListener("beforeunload", handleBeforeUnload);
    window.removeEventListener("unload", handleUnload);
  };
}, [isRefreshing, navigate]);

  return (
    <>
      <Banner Image={Images.about_banner} Title={"About Us"} />
      <div className={styles.visionCont}>
        <Headers title="Our Vision" data-aos="fade-left" />
        <div className={styles.mainCont} data-aos="fade-right">
          <div className={styles.leftCont}>
            <div className={styles.objCont}>
              <img src={Images.about_women} alt="about_women" loading="lazy" />
              <div className={styles.imgCont}>
                <p>
                  Your Trust, <br />
                  Our
                  <br /> Commitment to Excellence
                </p>
              </div>
            </div>
          </div>
          <div className={styles.rightCont}>
            <p>
              Our vision is to be a globally recognized leader, driving
              innovation in talent solutions, stock, and crypto services. We aim
              to empower clients by delivering exceptional value, fostering
              growth, and positively impacting the financial landscape.
            </p>
          </div>
        </div>
      </div>
      <div className={styles.galleryCont}>
        <Headers title="Our Gallery" />
        <div className={styles.galleryPic} data-aos="fade-left">
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            loop={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            Navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            breakpoints={{
              400: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 60,
              },
            }}
            className={`${styles.swiperTest} mySwiper`}>
            {gallery.map((item) => (
              <SwiperSlide className={styles.swiperBox} key={item.id}>
                <div className={styles.imgBox} key={item.id}>
                  <img src={item.image} alt="image_gallery" loading="lazy" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className={styles.crs_animat}>
        <img
          src={Images.f_logo}
          alt="logo_animate"
          data-aos="fade-up"
          loading="lazy"
        />
      </div>
      <div className={styles.teamCont}>
        <div className={styles.head} data-aos="fade-right">
          <h1>
            Time to <span>Meet</span> Our Team
          </h1>
        </div>
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          Navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          breakpoints={{
            400: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 30,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
          }}
          className={`${styles.swiperTeamCont} mySwiper`}>
          {Team.map((item) => (
            <SwiperSlide className={styles.swiperteam} key={item.id}>
              <div className={styles.teamBox} key={item.id}>
                <div className={item.name === "Shuaib" ? styles.leftImgShuaib : styles.leftImg}>
                  <img src={item.image} alt={item.name} loading="lazy" />
                </div>
                <div className={styles.rightBox}>
                  <h3>{item.name}</h3>
                  <h4>{item.designation}</h4>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default About;

import React, { useState, useEffect } from "react";
import styles from "./AdminMenu.module.css";
import Images from "../common/Images";
import { useNavigate } from "react-router-dom";
import Icons from "../common/Icons";
import { useData } from "../context/DataContext";

const AdminNavbar = () => {
  const navList = [
    {
      id: 1,
      name: "Dashboard",
      navigate: "dashboard",
      icons: <Icons.DashBoardIcon />,
    },
    {
      id: 2,
      name: "Job Lists",
      navigate: "jobs",
      icons: <Icons.JobIcon />,
    },
    {
      id: 3,
      name: "Master Data",
      navigate: "masterData",
      icons: <Icons.MasterData />,
    },
  ];

  const { setAdminDraw } = useData();

  const [activeItem, setActiveItem] = useState(null);
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setActiveItem(navList[0]);
  }, []);

  const handleNavigate = (list) => {
    setActiveItem(list);
    // alert(`/admin/${list.navigate}`)
    navigate(`/admin/${list.navigate}`);
    // console.log(`/admin/${list.navigate}`)
    // navigate(`/${list.navigate}`)
  };

  const handleDraw = () => {
    setAdminDraw((pre) => !pre);
    setOpen((pre) => !pre);
  };

  const IconComponent = open ? Icons.RightIcon : Icons.LeftIcon;

  return (
    <>
      <div className={open ? styles.navSmall : styles.navCont}>
        <div className={styles.circle}>
          <IconComponent className={styles.btnIcon} onClick={handleDraw} />
        </div>
        <div className={styles.imgBox}>
          <img
            src={Images.f_logo}
            alt="crs_logo"
            className={open ? styles.smallLog : styles.imgLog}
            loading="lazy"
          />
        </div>
        <p className={styles.border}></p>
        <div className={open ? styles.listing_small : styles.listing_menu}>
          <ul>
            {navList.map((item) => (
              <li
                key={item.id}
                className={
                  activeItem && activeItem.id === item.id
                    ? open
                      ? styles.activeCircle
                      : styles.active
                    : open
                    ? styles.activeLinkSmall
                    : styles.activeLink
                }
                onClick={() => handleNavigate(item)}>
                {open ? item.icons : item.name}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default AdminNavbar;

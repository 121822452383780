import React from "react";
import styles from "./MasterDataCard.module.css";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useData } from "../context/DataContext";

const MasterDataCards = ({ data }) => {
  const { setClient } = useData();

  const navigate = useNavigate();

  const handleView = (items) => {
    setClient(items);
    navigate("/admin/detail");
  };

  return (
    <>
      {data.map((item) => (
        <div className={styles.cardCont}>
          <h1>{item.userName}</h1>
          <h6>{item.company_name}</h6>
          <Button
            size="small"
            variant="contained"
            onClick={() => handleView(item)}
            className={styles.btns}>
            View
          </Button>
        </div>
      ))}
    </>
  );
};

export default MasterDataCards;

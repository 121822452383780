import React from "react";
import NavBar from "../components/NavBar";
import Main from "../components/Main";
import Footer from "../components/Footer";
import { useData } from "../context/DataContext";
import { AdminRoute } from "../components/Main";
import { Route, Routes } from "react-router-dom";

const Navigate = () => {
  const { isAuthenticate } = useData();

  return (
    <>
      {isAuthenticate ? (
        <>
          <Routes>
            <Route path="admin/*" element={<AdminRoute />} />
          </Routes>
        </>
      ) : (
        <>
          <div className="navBox">
            <NavBar />
          </div>
          <div className="main">
            <Main />
            <Footer />
          </div>
        </>
      )}
    </>
  );
};

export default Navigate;

import React, { useEffect, useState } from "react";
import styles from "./JobAdd.module.css";
import Icons from "../../common/Icons";
import InputFields from "../../components/InputFields";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useData } from "../../context/DataContext";
import axios from "axios";

const JobView = () => {
  const { adminJobData, url } = useData();

  const navigate = useNavigate();

  const [data, setDate] = useState(adminJobData);
  const [enableButton, setEnableButton] = useState(true);
  const [formData, setFormData] = useState({ ...adminJobData });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (enableButton) {
      setFormData({ ...adminJobData });
    }
  }, [enableButton, adminJobData]);

  const handleChange = (name, value) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setDate((data) => ({
      ...data,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    console.log(formData);

    // const requiredFields = [
    //   "jobCode",
    //   "industry",
    //   "designation",
    //   "location",
    //   "country",
    //   "description",
    //   "key_Responsibility",
    //   "preffered_Qualification",
    // ];

    // const newErrors = {};

    // requiredFields.forEach((field) => {
    //   if (!formData[field]) {
    //     newErrors[field] = `Please fill in ${field}`;
    //   }
    // });

    // if (Object.keys(newErrors).length > 0) {
    //   setErrors(newErrors);
    //   return;
    // }

    try {
      const response = await axios.put(
        `${url}/job/edit/${data.jobCode}`,
        formData
      );
      alert(response.data.message, "ssd");
      setErrors({});
      setEnableButton(true);
      navigate("/admin/jobs");
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  const handleEdit = (item) => {
    setEnableButton(false);
    setErrors({});
  };

  return (
    <>
      <div className={styles.topNavigation}>
        <h5
          className={styles.pathNonActive}
          onClick={() => navigate("/admin/jobs")}>
          Job Lists
        </h5>
        <Icons.RightIcon className={styles.icon} />
        <h5 className={styles.active}>{data.jobCode}</h5>
      </div>
      <div className={styles.formCont}>
        <div className={styles.setBox}>
          <InputFields
            field={true}
            placeHolder={"enter job code"}
            label={"job Code"}
            name={"jobCode"}
            onChange={handleChange}
            value={data.jobCode}
            className={styles.inputFields}
            error={errors.jobCode}
            activeButton={enableButton}
          />
          <InputFields
            field={true}
            placeHolder={"enter industry"}
            label={"Industry"}
            name={"industry"}
            onChange={handleChange}
            value={data.industry}
            className={styles.inputFields}
            error={errors.industry}
            activeButton={enableButton}
          />
        </div>
        <div className={styles.setBox}>
          <InputFields
            field={true}
            placeHolder={"enter Designation"}
            label={"Designation"}
            name={"designation"}
            onChange={handleChange}
            value={data.designation}
            className={styles.inputFields}
            error={errors.designation}
            activeButton={enableButton}
          />
          <div className={styles.secondIn}>
            <InputFields
              field={true}
              placeHolder={"enter location"}
              label={"location"}
              name={"location"}
              onChange={handleChange}
              value={data.location}
              className={styles.inputFields_sec}
              error={errors.location}
              activeButton={enableButton}
            />
            <InputFields
              field={true}
              placeHolder={"enter country"}
              label={"Country"}
              name={"country"}
              onChange={handleChange}
              value={data.country}
              className={styles.inputFields_sec}
              error={errors.country}
              activeButton={enableButton}
            />
          </div>
        </div>
        <div className={styles.setText}>
          <InputFields
            field={false}
            placeHolder={"enter the job link"}
            label={"job Link"}
            name={"job_link"}
            onChange={handleChange}
            value={data.job_link}
            className={styles.textDes}
            noRows={1}
            error={errors.job_link}
            activeButton={enableButton}
          />
        </div>
        <div className={styles.setText}>
          <InputFields
            field={false}
            placeHolder={"enter job description"}
            label={"Description"}
            name={"description"}
            onChange={handleChange}
            value={data.job_description}
            className={styles.textDes}
            noRows={5}
            error={errors.description}
            activeButton={enableButton}
          />
        </div>
        <div className={styles.setText}>
          <InputFields
            field={false}
            placeHolder={"enter key reponsibility"}
            label={"Key responsibility"}
            name={"key_Responsibility"}
            onChange={handleChange}
            value={data.key_Responsibility}
            className={styles.textDes}
            noRows={5}
            error={errors.key_Responsibility}
            activeButton={enableButton}
          />
        </div>
        <div className={styles.setText}>
          <InputFields
            field={false}
            placeHolder={"enter preferred qualification"}
            label={"preferred_Qualification"}
            name={"preferred_Qualification"}
            onChange={handleChange}
            value={data.preferred_Qualification}
            className={styles.textDes}
            noRows={5}
            error={errors.preferred_Qualification}
            activeButton={enableButton}
          />
        </div>
        <div className={styles.setText}>
          <InputFields
            field={false}
            placeHolder={"enter basic qualification"}
            label={"Basic Qualification"}
            name={"b_Qualification"}
            onChange={handleChange}
            value={data.b_Qualification}
            className={styles.textDes}
            noRows={5}
            activeButton={enableButton}
          />
        </div>
        <div className={styles.btnContView}>
          <div className={styles.leftBtn}>
            {!enableButton ? (
              <Button
                size="small"
                variant="contained"
                type="submit"
                id="submitbtn"
                onClick={handleSave}
                className={styles.btns}>
                Save
              </Button>
            ) : (
              <Button
                size="small"
                variant="contained"
                type="submit"
                id="submitbtn"
                onClick={() => handleEdit(adminJobData)}
                className={styles.btns}>
                Edit
              </Button>
            )}
          </div>
          <div className={styles.rightBtn}>
            <Button
              size="small"
              variant="contained"
              type="submit"
              id="submitbtn"
              onClick={() => navigate("/admin/jobs")}
              className={styles.btnCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobView;

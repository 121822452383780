import React from "react";
import styles from "./DataView.module.css";
import Icons from "../../common/Icons";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Images from "../../common/Images";
import { useData } from "../../context/DataContext";
import { saveAs } from "file-saver";
import axios from "axios";

const DataView = () => {
  const { clientDetail, url } = useData();

  const navigate = useNavigate();

  // const handleDownload = async (resumeUrl) => {
  //   try {
  //     // Convert Buffer to Uint8Array
  //     const uint8Array = new Uint8Array(resumeUrl.data);

  //     // Convert Uint8Array to Blob
  //     const blob = new Blob([uint8Array], { type: "application/pdf" });

  //     // Save Blob as file
  //     saveAs(blob, "c_resume.pdf");
  //   } catch (error) {
  //     console.error("Error downloading resume:", error);
  //   }
  // };

  // const handleDownload = async (resumeUrl) => {
  //   try {
  //     const response = await fetch(resumeUrl);

  //     // Set Content-Type header
  //     response.headers.forEach((value, name) => {
  //       if (name.toLowerCase() === "content-type") {
  //         const contentType = value;
  //         console.log("Content-Type:", contentType);
  //         // You can set the Content-Type header in your download request
  //         // e.g., if you are using the 'file-saver' library:
  //         saveAs(response.url, "resume.pdf", { type: contentType });
  //       }
  //     });
  //   } catch (error) {
  //     console.error("Error downloading resume:", error);
  //   }
  // };

  const handleDownload = async (candidateDetail) => {
    // console.log('rrr', resumeUrl.data)

    // try {
    //   const response = await fetch(resumeUrl);
    //   const blob = await response.blob();
    //   saveAs(blob, "resume.pdf");
    // } catch (error) {
    //   console.error("Error downloading resume:", error);
    // }
    // try {
    //   const response = await fetch(resumeUrl);
    //   console.log("Content-Type:", response.headers.get("Content-Type"));

    //   const blob = await response.blob();
    //   saveAs(blob, "resume.pdf");
    // } catch (error) {
    //   console.error("Error downloading resume:", error);
    // }
    try {
      const response = await fetch(`/download/${candidateDetail.id}`);
      const blob = await response.blob();

      saveAs(blob, `${candidateDetail.userName} resume.pdf`);
    } catch (error) {
      console.error("Error downloading resume:", error);
    }
  };

  return (
    <>
      <div className={styles.viewContOuter}>
        <h1>Go to see ahead....</h1>
        <div className={styles.viewCont}>
          <div className={styles.viewInnerCont}>
            <div className={styles.titles}>
              <h1>{clientDetail.userName}</h1>
              <h2>{clientDetail.company_name}</h2>
            </div>
            <div className={styles.socialBox}>
              <div className={styles.social}>
                <Icons.CallIcon />
                <p>{clientDetail.mobile_number || "mobile is not entered"}</p>
              </div>
              <div className={styles.social}>
                <Icons.Email />
                <p>{clientDetail.email || "email is not entered"}</p>
              </div>
            </div>
            <div className={styles.descripBox}>
              <Icons.Comments />
              <textarea
                className={styles.textBox}
                rows={4}
                value={clientDetail.message || "no message has entered"}
                disabled={true}
                placeholder="the comments are...."></textarea>
            </div>
            <div className={styles.resumeBtn}>
              {clientDetail.resumes && (
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => handleDownload(clientDetail)}
                  className={styles.btnRes}>
                  Resume
                </Button>
              )}
            </div>
            <div className={styles.btnBack}>
              <Button
                size="small"
                variant="contained"
                onClick={() => navigate("/admin/masterData")}
                className={styles.btns}>
                Go Back
              </Button>
            </div>
          </div>
          <div className={styles.leftDesign}>
            <img src={Images.adminDetail} alt="detailGif" loading="lazy" />
          </div>
        </div>
      </div>
    </>
  );
};

export default DataView;

import React, { useEffect, useState } from "react";
import { useData } from "../../context/DataContext";
import styles from "./Dashboard.module.css";
import axios from "axios";

const Dashboard = () => {
  const { url } = useData();
  const [totalJob, setTotalJob] = useState();
  const [totalCandidate, setTotalCandidate] = useState();
  const [totalCorporate, setTotalCorporate] = useState();

  const dashBox = [
    {
      id: 1,
      title: "Total job Post",
      count: totalJob,
    },
    {
      id: 2,
      title: "Total Candidate Enquiry",
      count: totalCandidate,
    },
    {
      id: 3,
      title: "Total Client Enquiry",
      count: totalCorporate,
    },
  ];

  useEffect(() => {
    fetchingDatas();
  }, []);

  const fetchingDatas = async () => {
    try {
      const jobList = await axios.get(`${url}/job`);
      const candidatesResponse = await axios.get(`${url}/candidate`);
      const corporatesResponse = await axios.get(`${url}/corporate`);
      setTotalJob(jobList.data.length);
      setTotalCandidate(candidatesResponse.data.length);
      setTotalCorporate(corporatesResponse.data.length);
      console.log(
        "Data fetched successfully for candidates and corporates",
        jobList
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  return (
    <>
      <>
        <div className={styles.mainCont}>
          <div className={styles.head}>
            <h1>
              Welcome, to <span>DashBoard</span>
            </h1>
            <p className={styles.brdr}></p>
          </div>
          <div className={styles.cardCont}>
            {dashBox.map((item) => (
              <div className={styles.cardBox} key={item.id}>
                <h2>{item.title}</h2>
                <h1>{item.count >= 0 ? item.count : 0}</h1>
              </div>
            ))}
          </div>
        </div>
      </>
    </>
  );
};

export default Dashboard;

import React, { useState } from "react";
import styles from "./CareersView.module.css";
import { useNavigate } from "react-router-dom";
import { useData } from "../context/DataContext";
import Button from "@mui/material/Button";
import Icons from "../common/Icons";

const CareersView = () => {
  const { data } = useData();

  const [stickChange, setStickChange] = useState(false);

  const navigate = useNavigate();

  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setStickChange(true);
    } else {
      setStickChange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  const handleApply = (applyLink) => {
    window.open(applyLink, "_blank");
  }

  return (
    <>
      <div className={stickChange ? styles.stickTopCont : styles.topCont}>
        <div className={styles.titleCont}>
          <div className={styles.arrow}>
            <h5>{data.designation}</h5>
            <h6>
              {data.jobCode} &nbsp; | &nbsp;{data.location}
            </h6>
          </div>
        </div>
        <div className={styles.applyCont}>
          <Button variant="contained" className={styles.btn} onClick={() => handleApply(data.job_link)}>
            Apply know
          </Button>
        </div>
      </div>
      <div className={styles.mainCont}>
        <div className={styles.subMain}>
          <Icons.ArrowBack
            className={styles.icons}
            onClick={() => navigate("/careers/jobs")}
          />
          <div className={styles.descont}>
            <div className={styles.descripCont}>
              <h4>Description</h4>
              <p>{data.job_description}</p>

              <h4>Key Responsibilites</h4>
              <ul>
                <li>{data.key_Responsibility} </li>
              </ul>
              <h4>Prefered Requirements</h4>
              <ul>
                <li>{data.preferred_Qualification}</li>
              </ul>
              <h4>Basic Qualification</h4>
              <ul>
                <li>{data.b_Qualification}</li>
              </ul>
            </div>
            <div className={styles.detailJobCont}>
              <div className={styles.detailCont}>
                <h5>Job Details</h5>
              </div>
              <div className={styles.detailCont}>
                <Icons.LocationIcon className={styles.iconsDet} />
                <p>
                  {data.location}, {data.country}
                </p>
              </div>
              <div className={styles.detailCont}>
                <Icons.TechSuit className={styles.iconsDet} />
                <p>On-site</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CareersView;

import React, { useEffect, useState } from "react";
import styles from "./MasterData.module.css";
import MasterDataCards from "../../components/MasterDataCards";
import axios from "axios";
import { useData } from "../../context/DataContext";
import Images from "../../common/Images";

const MasterData = () => {
  const navHead = [
    {
      id: 1,
      name: "corporate",
      navigate: "corporate",
      active: true,
    },
    {
      id: 2,
      name: "candidate",
      navigate: "candidate",
      active: false,
    },
  ];

  const { url } = useData();
  const [navMas, setNavMas] = useState(navHead);
  const [data, setData] = useState([]);

  useEffect(() => {
    initialFetchData();
  }, []);

  const fetchData = async (dataName) => {
    try {
      const response = await axios.get(`${url}/${dataName}`);
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const initialFetchData = async () => {
    const activeCategory = navMas.find((navItem) => navItem.active).name;
    await fetchData(activeCategory);
  };

  const handleClick = async (item) => {
    const updatedNav = navMas.map((navItem) => ({
      ...navItem,
      active: navItem.id === item.id,
    }));
    setNavMas(updatedNav);
    const activeCategory = updatedNav.find((navItem) => navItem.active).name;
    await fetchData(activeCategory);
  };

  return (
    <>
      <div className={styles.masterCont}>
        {navMas.map((item) => (
          <div
            className={item.active ? styles.navMastActive : styles.navMastCont}
            key={item.id}
            onClick={() => handleClick(item)}>
            <h2>{item.name}</h2>
          </div>
        ))}
      </div>
      <div className={styles.masterComponent}>
        {data && data ? (
          <MasterDataCards data={data} />
        ) : (
          <div className={styles.placeHolderLogo}>
            <img src={Images.crs_logo} alt="placeholder logo" loading="lazy" />
            <h3>No Data Listed yet!!</h3>
          </div>
        )}
      </div>
    </>
  );
};

export default MasterData;

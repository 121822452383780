import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "./Cards.module.css";

const Cards = ({ Images, content, navigation, keys }) => {
  const navigate = useNavigate();

  return (
    <>
      <Card sx={{ maxWidth: 345 }} key={keys} className={styles.cardCont}>
        <CardActionArea className={styles.cardContainer}>
          <CardMedia
            component="img"
            image={Images}
            alt=""
            className={styles.cardImage}
          />
          <CardContent>
            <Typography variant="body2" className={styles.contents}>
              {content}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button
            size="small"
            variant="contained"
            onClick={() => navigate(navigation)}
            className={styles.btns}>
            More
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

export default Cards;

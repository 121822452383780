import React, { useState, useEffect } from "react";
import { useData } from "../../context/DataContext";
import styles from "./Login.module.css";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import { Button } from "@mui/material";
import Icons from "../../common/Icons";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const { setAuthenticate } = useData();
  const navigate = useNavigate();

  const quotes = [
    {
      id: 1,
      quote: "Success is sum of small efforts, repeated day in and day out.",
    },
    {
      id: 2,
      quote: "You miss 100% of the shots you don't take.",
    },
    {
      id: 3,
      quote:
        "If You're a recruiter and you don't see yourself as a marketr, you're in the wrong profession.",
    },
    {
      id: 4,
      quote: "To win the market place, you have to win the work place.",
    },
    {
      id: 5,
      quote:
        "Hiring the right people take time, the right questions, and a healthy dose of curosity.",
    },
    {
      id: 6,
      quote: "The Harder you work, the luckier you get.",
    },
    {
      id: 7,
      quote: "Make this Day great!",
    },
    {
      id: 8,
      quote: "If you're not netwrorking, you're not working.",
    },
    {
      id: 9,
      quote: "Growth takes place outside of your comfort zone.",
    },
    {
      id: 10,
      quote:
        "If you're not taking care of your client, then your competitor will.",
    },
  ];

  const adminLogin = {
    userId: "crsadmin@crs.com",
    security: "123456",
  };
  const [currentQuoteIndex, setCurrentQuoteIndex] = useState(0);
  const [show, setShow] = useState(false);
  const [admin, setAdmin] = useState({
    name: "",
    email: "",
  });

  const [formE, setFormE] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAdmin((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentQuoteIndex((prevIndex) => (prevIndex + 1) % quotes.length);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate(admin);

    if (Object.keys(errors).length === 0) {
      setAuthenticate(true);
      navigate("/admin/dashboard");
    } else {
      setFormE(errors);
    }
  };

  const validate = (admin) => {
    let er = {};
    const errs = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (!admin.email) {
      er.email = "Please fill the email";
    } else if (!errs.test(admin.email)) {
      er.email = "Please enter valid email";
    } else if (admin.email !== adminLogin.userId) {
      er.email = "UserId didn't match";
    }
    if (!admin.password) {
      er.password = "Please fill the password";
    } else if (admin.password !== adminLogin.security) {
      er.password = "Password didn't match";
    } else;
    return er;
  };

  const handleShow = () => {
    setShow((pre) => !pre);
  };

  return (
    <>
      <div className={styles.outerMain}>
        <div className={styles.mainCont}>
          <div className={styles.left_cont}>
            <FormatQuoteIcon
              className={styles.icon}
              sx={{ color: "#022744" }}
            />
            <div
              className={styles.upperquotes}
              style={{ opacity: currentQuoteIndex === 0 ? 1 : 1 }}>
              <h4>{quotes[currentQuoteIndex].quote}</h4>
            </div>
          </div>
          <div className={styles.right_cont}>
            <div className={styles.form_container}>
              <div id="form" className={styles.form}>
                <h2>
                  Log<span>In</span>
                </h2>
                <div className={styles.form_content}>
                  <div className={styles.form_group}>
                    <div className={styles.form_column}>
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Enter email"
                        onChange={handleChange}
                      />
                      <Icons.Email className={styles.i} />
                    </div>
                    <div className={styles.error}>{formE.email}</div>
                  </div>

                  <div className={styles.form_group}>
                    <div className={styles.form_column}>
                      <label htmlFor="password">Password</label>
                      <input
                        type={show ? "text" : "password"}
                        name="password"
                        id="password"
                        placeholder="Enter password"
                        maxLength={16}
                        onChange={handleChange}
                      />
                      {show ? (
                        <Icons.InVisibleIcon
                          className={styles.i}
                          onClick={handleShow}
                        />
                      ) : (
                        <Icons.VisibleIcon
                          className={styles.i}
                          onClick={handleShow}
                        />
                      )}
                    </div>
                    <div className={styles.error}>{formE.password}</div>
                  </div>
                </div>
                <Button
                  size="small"
                  variant="contained"
                  type="submit"
                  id="submitbtn"
                  onClick={handleSubmit}
                  className={styles.btns}>
                  logIn
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;

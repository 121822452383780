import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Contact.module.css";
import Images from "../common/Images";
import Button from "@mui/material/Button";
import axios from "axios";
import { useData } from "../context/DataContext";

const Contactus = () => {
  const cont = [
    {
      id: 1,
      title: "corporate",
      active: true,
    },
    {
      id: 2,
      title: "candidate",
      active: false,
    },
  ];

  const { url } = useData();
  const navigate = useNavigate();
  const [buttonClicked, setButtonClicked] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [formData, setFormData] = useState(() => {
    return {
      userName: "",
      mobile_number: "",
      email: "",
      message: "",
      ...(cont[0].title === "corporate" ? { company_name: "" } : {}),
      ...(cont[0].title === "candidate" ? { resumes: "" } : {}),
    };
  });
  useEffect(() => {
    setActiveItem(cont[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (item) => {
    setActiveItem(item);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "mobile_number" && value.length === 11) {
      alert("Mobile number should be a maximum of 10 characters");
      return;
    }
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async () => {
    console.log("data", formData);

    if (
      !formData.userName ||
      !formData.mobile_number ||
      !formData.email ||
      !formData.message ||
      (activeItem.title === "corporate" && !formData.company_name) ||
      (activeItem.title === "candidate" && !formData.resumes)
    ) {
      alert("Please fill out all required fields");
      return;
    }

    try {
      const response = await axios.post(
        `${url}/${activeItem.title}/create`,
        formData
      );
      console.log("respon", response.data);
      setButtonClicked(true)
      alert("You will get a call from a company shortly!!");
      navigate("/");
    } catch (error) {
      alert(error.response.data.error);
    }
    setFormData({
      userName: "",
      mobile_number: "",
      email: "",
      message: "",
      ...(activeItem.title === "corporate"
        ? { company_name: "" }
        : { company_name: "" }),
      ...(activeItem.title === "candidate" ? { resumes: "" } : { resumes: "" }),
    });
  };

  return (
    <>
      <div className={styles.contactCont}>
        <div className={styles.banner}>
          <img src={Images.contactBanner} alt="contact_banner" loading="lazy" />
        </div>
        <div className={styles.contactForm}>
          <div className={styles.heading}>
            {cont.map((item) => (
              <h5
                key={item.id}
                className={
                  item.id === activeItem?.id
                    ? styles.listActive
                    : styles.notActive
                }
                onClick={() => handleClick(item)}>
                {item.title}
              </h5>
            ))}
          </div>
          {activeItem && (
            <div className={styles.form}>
              <div className={styles.left}>
                <img
                  src={
                    activeItem.title === "corporate"
                      ? Images.contactCorporate
                      : Images.contactCandidate
                  }
                  alt={activeItem.title}
                />
              </div>
              <div className={styles.right}>
                <h4>
                  Request a{" "}
                  <span style={{ fontWeight: "bold" }}>Call back</span>
                </h4>
                <div className={styles.formInp}>
                  <div className={styles.inputBox}>
                    <input
                      placeholder="your name ⁕"
                      type="text"
                      min={5}
                      name="userName"
                      value={formData.userName}
                      onChange={handleChange}
                    />
                    <input
                      placeholder="phone number ⁕"
                      type="number"
                      minLength={10}
                      maxLength={14}
                      name="mobile_number"
                      value={formData.mobile_number}
                      onChange={handleChange}
                    />
                  </div>
                  <div className={styles.inputBox}>
                    <input
                      placeholder="your e-mail ⁕"
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {activeItem.title === "candidate" ? (
                      <input
                        type="file"
                        className={styles.files}
                        accept=".pdf"
                        name="resumes"
                        value={formData.resumes}
                        onChange={handleChange}
                      />
                    ) : (
                      <input
                        placeholder="company name ⁕"
                        type="text"
                        name="company_name"
                        value={formData.company_name}
                        onChange={handleChange}
                      />
                    )}
                  </div>
                  <div className={styles.inputBox}>
                    <textarea
                      placeholder="Message"
                      rows={4}
                      name="message"
                      value={formData.message}
                      onChange={handleChange}></textarea>
                  </div>
                  <div className={styles.inputBox}>
                    <Button
                      variant="contained"
                      disabled={buttonClicked}
                      className={styles.btn}
                      onClick={() => handleSubmit()}>
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Contactus;

import React from "react";
import styles from "./Loader.module.css";
import CircularProgress from "@mui/material/CircularProgress";
import { useData } from "../context/DataContext";

const Loader = () => {
  const { loading } = useData();

  return (
    <>
      {loading ? (
        <div className={styles.loaderCont}>
          <CircularProgress disableShrink />
          <p>
            I appreciate your patience,
            <br /> We will be with you in shortly.....
          </p>
        </div>
      ) : null}
    </>
  );
};

export default Loader;

"use client";
import React, { useState } from "react";
import styles from "./JobAdd.module.css";
import Icons from "../../common/Icons";
import InputFields from "../../components/InputFields";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useData } from "../../context/DataContext";
import axios from "axios";

const JobAdd = () => {
  const { url } = useData();

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    jobCode: "",
    industry: "",
    designation: "",
    location: "",
    country: "",
    job_link: "",
    job_description: "",
    key_Responsibility: "",
    preferred_Qualification: "",
    b_Qualification: "",
    post_date: new Date(),
  });
  const [errors, setErrors] = useState({});

  const handleChange = (fieldName, value) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: "",
    }));

    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const handleSave = async () => {
    console.log(formData);

    const requiredFields = [
      "jobCode",
      "industry",
      "designation",
      "location",
      "country",
      "job_link",
      "job_description",
      "key_Responsibility",
      "preferred_Qualification",
    ];

    const newErrors = {};

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `Please fill in ${field}`;
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const response = await axios.post(`${url}/job/create`, formData);
      setErrors({});
      alert(response.data.message);
      navigate("/admin/jobs");
    } catch (error) {
      alert(error.response.data.error);
    }
  };

  return (
    <>
      <div className={styles.topNavigation}>
        <h5
          className={styles.pathNonActive}
          onClick={() => navigate("/admin/jobs")}>
          Job Lists
        </h5>
        <Icons.RightIcon className={styles.icon} />
        <h5 className={styles.active}>Create Job</h5>
      </div>
      <div className={styles.formCont}>
        <div className={styles.setBox}>
          <InputFields
            field={true}
            placeHolder={"enter job code"}
            label={"jobCode"}
            name={"jobCode"}
            onChange={handleChange}
            value={formData.jobCode}
            className={styles.inputFields}
            error={errors.jobCode}
          />
          <InputFields
            field={true}
            placeHolder={"enter industry"}
            label={"Industry"}
            name={"industry"}
            onChange={handleChange}
            value={formData.industry}
            className={styles.inputFields}
            error={errors.industry}
          />
        </div>
        <div className={styles.setBox}>
          <InputFields
            field={true}
            placeHolder={"enter Designation"}
            label={"Designation"}
            name={"designation"}
            onChange={handleChange}
            value={formData.designation}
            className={styles.inputFields}
            error={errors.designation}
          />
          <div className={styles.secondIn}>
            <InputFields
              field={true}
              placeHolder={"enter location"}
              label={"location"}
              name={"location"}
              onChange={handleChange}
              value={formData.location}
              className={styles.inputFields_sec}
              error={errors.location}
            />
            <InputFields
              field={true}
              placeHolder={"enter country"}
              label={"Country"}
              name={"country"}
              onChange={handleChange}
              value={formData.country}
              className={styles.inputFields_sec}
              error={errors.country}
            />
          </div>
        </div>
        <div className={styles.setText}>
          <InputFields
            field={false}
            placeHolder={"enter job application link"}
            label={"job Link"}
            name={"job_link"}
            onChange={handleChange}
            value={formData.job_link}
            className={styles.textDes}
            noRows={1}
            error={errors.job_link}
          />
        </div>
        <div className={styles.setText}>
          <InputFields
            field={false}
            placeHolder={"enter job job_description"}
            label={"job_description"}
            name={"job_description"}
            onChange={handleChange}
            value={formData.job_description}
            className={styles.textDes}
            noRows={5}
            error={errors.job_description}
          />
        </div>
        <div className={styles.setText}>
          <InputFields
            field={false}
            placeHolder={"enter key reponsibility"}
            label={"Key responsibility"}
            name={"key_Responsibility"}
            onChange={handleChange}
            value={formData.key_Responsibility}
            className={styles.textDes}
            noRows={5}
            error={errors.key_Responsibility}
          />
        </div>
        <div className={styles.setText}>
          <InputFields
            field={false}
            placeHolder={"enter preferred qualification"}
            label={"Preferred Qualification"}
            name={"preferred_Qualification"}
            onChange={handleChange}
            value={formData.preferred_Qualification}
            className={styles.textDes}
            noRows={5}
            error={errors.preferred_Qualification}
          />
        </div>
        <div className={styles.setText}>
          <InputFields
            field={false}
            placeHolder={"enter basic qualification"}
            label={"Basic Qualification"}
            name={"b_Qualification"}
            onChange={handleChange}
            value={formData.b_Qualification}
            className={styles.textDes}
            noRows={5}
          />
        </div>
        <div className={styles.btnCont}>
          <Button
            size="small"
            variant="contained"
            type="submit"
            id="submitbtn"
            onClick={handleSave}
            className={styles.btns}>
            Save
          </Button>
          <Button
            size="small"
            variant="contained"
            type="submit"
            id="submitbtn"
            onClick={() => navigate("/admin/jobs")}
            className={styles.btnCancel}>
            Cancel
          </Button>
        </div>
      </div>
    </>
  );
};

export default JobAdd;

import React, { useState } from "react";
import styles from "./Common.module.css";
import Icons from "../../common/Icons";
import { useNavigate } from "react-router-dom";
import { useData } from "../../context/DataContext";

const HeaderProfile = () => {
  const { setAuthenticate } = useData();
  const navigate = useNavigate();

  const [openDrawer, setOpenDrawer] = useState(false);

  const handleClick = () => {
    setOpenDrawer((pre) => !pre);
  };

  const handleLogOut = async () => {
    await setAuthenticate(false);
    navigate("/");
  };

  return (
    <>
      <div className={styles.headerCont}>
        <Icons.Profile onClick={handleClick} className={styles.icons} />
      </div>
      {openDrawer && (
        <div className={styles.drawer}>
          <p>Profile</p>
          <p onClick={handleLogOut}>Logout</p>
        </div>
      )}
    </>
  );
};

export default HeaderProfile;

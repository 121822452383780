import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Navigate from "./routes/Navigate";
import Aos from "aos";
import "aos/dist/aos.css";
import { DataProvider } from "./context/DataContext";

function App() {


  useEffect(() => {
    const handleResize = () => {
      const screenWidth =
        window.innerWidth || document.documentElement.clientWidth;
      if (screenWidth > 980) {
        Aos.init({
          offset: 200,
          duration: 2000,
          easing: "ease",
          delay: 0,
        });
      } else {
        Aos.init({ disable: true });
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <BrowserRouter>
        <DataProvider>
          <Navigate />
        </DataProvider>
      </BrowserRouter>
    </>
  );
}

export default App;

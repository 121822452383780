import React, { useState, useEffect } from "react";
import styles from "./JobList.module.css";
import Icons from "../../common/Icons";
import { Button } from "@mui/material";
import Paginations from "../../components/Paginations";
import Images from "../../common/Images";
import { useData } from "../../context/DataContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const JobList = () => {
  const { setAdminJobDetail, url } = useData();

  const navigate = useNavigate();
  const [getData, setGetData] = useState("");
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const pageData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    let dataToDisplay;

    if (filterData.length > 0) {
      dataToDisplay = filterData.slice(startIndex, endIndex);
    } else {
      dataToDisplay = data.slice(startIndex, endIndex);
    }
    setData(dataToDisplay);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    pageData();
  }, [filterData, currentPage]);

  const fetchData = async () => {
    const response = await axios.get(`${url}/job`);
    if (response) {
      setData(response.data);
    } else {
      setData([]);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGetData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSearch = () => {
    const filteredData = data.filter((career) => {
      const jobTitleMatch =
        !getData ||
        career.designation.toLowerCase().includes(getData.toLowerCase());
      const locationMatch =
        !getData ||
        career.location.toLowerCase().includes(getData.toLowerCase());

      return jobTitleMatch && locationMatch;
    });
    setFilterData(filteredData);

    setCurrentPage(1);
    setGetData("");
  };

  const handleView = (dataView) => {
    setAdminJobDetail(dataView);
    navigate("/admin/jobUpdates");
  };

  const handleDelete = async (deleteItem) => {
    const textPrompt = `Are you sure you want to delete ${deleteItem.jobCode}`;

    if (window.confirm(textPrompt) === true) {
      const response = await axios.delete(`${url}/job/${deleteItem.jobCode}`);
      if (response) {
        setData(response.data);
      }
    } else {
      alert("You have cancel the deletation");
    }
  };

  return (
    <>
      <div className={styles.jobCont}>
        <div className={styles.filterCont}>
          <div className={styles.searchDiv}>
            <input
              type="text"
              placeholder="find the job by designation,location"
              onChange={handleChange}
            />
            <Icons.SearchIcon className={styles.iconSearch} />
          </div>
          <div
            className={styles.btnBox}
            onClick={() => navigate("/admin/createJob")}>
            <p>Add Jobs</p>
            <Icons.AddCircleIcon
              className={styles.add}
              onClick={handleSearch}
            />
          </div>
        </div>
        <p className={styles.borderBottom}></p>
      </div>
      <div className={styles.listOut}>
        {data.length > 0 ? (
          data.map((item) => (
            <div className={styles.listIn} key={item.id}>
              <div className={styles.upperLayer}>
                <h1>{item.designation}</h1>
                {/* <h6>Posted {item.date}</h6> */}
              </div>
              <h6 className={styles.locat}>
                {item.jobCode} - {item.location}, {item.country}
              </h6>
              <div className={styles.bottomLayer}>
                <div className={styles.leftBtnBox}>
                  <Button
                    size="small"
                    variant="contained"
                    type="submit"
                    id="submitbtn"
                    onClick={() => handleView(item)}
                    className={styles.btns}>
                    View
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    type="submit"
                    id="submitbtn"
                    onClick={() => handleDelete(item)}
                    className={styles.btns}>
                    Delete
                  </Button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className={styles.placeHolderLogo}>
            <img src={Images.crs_logo} alt="placeholder logo" loading="lazy" />
            <h3>No Job Listed yet!!</h3>
          </div>
        )}
      </div>
      <div className={styles.paginate}>
        <Paginations
          className={styles.pagin}
          activePage={currentPage}
          onPageChange={handlePageChange}
          itemCount={data.length}
          itemsPerPage={itemsPerPage}
        />
      </div>
    </>
  );
};

export default JobList;

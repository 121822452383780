import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import LockIcon from "@mui/icons-material/Lock";
import StarIcon from "@mui/icons-material/Star";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AutoModeIcon from "@mui/icons-material/AutoMode";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import HomeIcon from "@mui/icons-material/Home";
import WorkIcon from "@mui/icons-material/Work";
import TableChartIcon from "@mui/icons-material/TableChart";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";

const Icons = {
  DownArrow: KeyboardArrowDownIcon,
  CallIcon: PhoneIcon,
  Email: EmailIcon,
  LinkedIn: LinkedInIcon,
  Instagram: InstagramIcon,
  FaceBook: FacebookIcon,
  Twitter: XIcon,
  LockIcon: LockIcon,
  StarIcon: StarIcon,
  LocationIcon: LocationOnIcon,
  SearchIcon: SearchIcon,
  ArrowBack: ArrowBackIcon,
  TechSuit: AutoModeIcon,
  VisibleIcon: RemoveRedEyeIcon,
  InVisibleIcon: VisibilityOffIcon,
  Profile: AccountCircleIcon,
  RightIcon: NavigateNextIcon,
  LeftIcon: NavigateBeforeIcon,
  DashBoardIcon: HomeIcon,
  JobIcon: WorkIcon,
  MasterData: TableChartIcon,
  AddCircleIcon: AddCircleIcon,
  Comments: SpeakerNotesIcon,
};

export default Icons;

import React from "react";
import styles from "./InputFields.module.css";

const InputFields = ({
  label,
  className,
  placeHolder,
  noRows,
  field,
  name,
  value,
  onChange,
  error,
  activeButton,
}) => {
  const dataDiv = field;

  return (
    <>
      {dataDiv ? (
        <div className={styles.fieldBox}>
          <label>{label}</label>
          <input
            type="text"
            className={`${styles.fieldInput} ${className}`}
            placeholder={placeHolder}
            name={name}
            value={value}
            onChange={(e) => onChange(name, e.target.value)}
            disabled={activeButton}
          />
          {error && <p className={styles.error}>{error}</p>}
        </div>
      ) : (
        <div className={styles.textBox}>
          <div>
            <label>{label}</label>
          </div>
          <textarea
            type="text"
            className={`${styles.fieldText} ${className}`}
            placeholder={placeHolder}
            name={name}
            rows={noRows}
            value={value}
            onChange={(e) => onChange(name, e.target.value)}
            disabled={activeButton}></textarea>
          {error && <p className={styles.error}>{error}</p>}
        </div>
      )}
    </>
  );
};

export default InputFields;
